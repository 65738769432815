import { PropsWithChildren, useContext } from "react";
import { Caption1, MessageBar, MessageBarBody, MessageBarTitle, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PPAContext } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


export default function Disabled(props: any) {
    const ppaContext = useContext(PPAContext);
    const { t } = useL10n<ILocaleStrings>();

    return (
        <ScreenPlaceholder
            imageUrl="images/DisabledImage.png"
            title={t("ppaNoAccessTitle")}
            message={t("ppaSignupDisabledMessage")} />
    );
}