import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Caption1, MessageBar, MessageBarBody, MessageBarTitle, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PPAContext } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { format, parseISO } from "date-fns";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


export default function LicenceExpired(props: any) {
    const ppaContext = useContext(PPAContext);
    const { t } = useL10n<ILocaleStrings>();

    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (ppaContext.licenceExpiry) {
            const expiry = parseISO(ppaContext.licenceExpiry);
            setMessage(t("ppaLicenseExpiredMessage", { expiry: format(expiry, "PPp") }));
        }
        else {
            setMessage(t("ppaNoLicenseMessage"));
        }
    }, []);

    return (
        <ScreenPlaceholder
            imageUrl="images/LicenceImage.png"
            title={t("ppaNotLicensedTitle")}
            message={message} />
    );
}