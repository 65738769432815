import { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { Button, Label, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens, useId } from "@fluentui/react-components";
import {
    ChevronLeftRegular,
    ChevronRightRegular,
    ChevronDownRegular
} from "@fluentui/react-icons";
import { PPAContext } from "context/ppa-context";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IGridPagingProps } from "./grid-paging.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        ...shorthands.margin('0.5em', 0),
        //backgroundColor: '#eee',
        // ...shorthands.borderWidth('1px', '0'),
        // ...shorthands.borderStyle('solid', 'none'),
        // ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // ...shorthands.padding('0', '2em')
        '> div': {
            //backgroundColor: 'red',
            ...shorthands.flex(1),
            display: 'flex',
            alignItems: 'center',
            //
        }
    },
    buttons: {
        //     paddingLeft: 0,
        //     paddingRight: 0,
        //     justifyContent: 'space-between'
        justifyContent: 'center'
    },
    buttonsLabel: {
        ...shorthands.margin(0, '0.5em')
    },
    limit: {
        //     display: "inline-flex",
        //     alignItems: "center"
        justifyContent: 'flex-end'
    },
    limitButton: {
        minWidth: '4em',
        ...shorthands.padding('', 0),
        ...shorthands.margin(0, 0, 0, '0.5em')
    }
});

export default function GridPaging<T>(props: IGridPagingProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();
    const pagingLimitId = useId("ppa-paging-limit");

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [startItem, setStartItem] = useState<number>(0);
    const [endItem, setEndItem] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => {
        if (currentPage && itemsPerPage && props.items) {
            const startIdx = (currentPage - 1) * itemsPerPage

            const endIdx = (startIdx + itemsPerPage) < props.items.length ?
                startIdx + itemsPerPage :
                props.items.length;

            if (startIdx >= 0 && startIdx <= props.items.length) {
                props.setItems(props.items.slice(startIdx, endIdx));
                setStartItem(startIdx + 1);
                setEndItem(endIdx);
                setTotalItems(props.items.length);
            }
        }
        else {
            props.setItems([]);
        }
    }, [props.items, currentPage, itemsPerPage]);

    const pageCount = useMemo(() => {
        return props.items ? Math.ceil(props.items.length / itemsPerPage) : 0;
    }, [props.items, itemsPerPage]);

    const canShowPrevious = useMemo(() => {
        return currentPage > 1;
    }, [currentPage]);

    const canShowNext = useMemo(() => {
        return currentPage < pageCount;
    }, [currentPage, pageCount]);

    const showNextPage = () => {
        if (currentPage < pageCount)
            setCurrentPage(currentPage + 1);
    }

    const showPreviousPage = () => {
        if (currentPage > 1)
            setCurrentPage(currentPage - 1);
    }

    return (
        <div className={styles.mainWrapper}>
            <div>{/* spacer only */}</div>
            <div className={styles.buttons}>
                <Button
                    appearance="subtle"
                    icon={<ChevronLeftRegular />}
                    onClick={showPreviousPage}
                    disabled={!canShowPrevious} />
                <Label className={styles.buttonsLabel}>{totalItems > 0 && <span>{t('pagingItemsAToBLabel', { startItem: startItem, endItem: endItem, totalItems: totalItems })}</span>}</Label>
                {/*  */}
                <Button
                    appearance="subtle"
                    icon={<ChevronRightRegular />}
                    onClick={showNextPage}
                    disabled={!canShowNext} />
            </div>
            <div className={styles.limit}>
                <Label htmlFor={pagingLimitId}>{t('pagingItemsPerPageLabel')}</Label>
                <Menu onCheckedValueChange={(ev, data) => { console.log(data) }}>
                    <MenuTrigger disableButtonEnhancement>
                        <MenuButton
                            id={pagingLimitId}
                            className={styles.limitButton}
                            size="medium"
                            appearance="subtle"
                        >{itemsPerPage}</MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList >
                            <MenuItem onClick={() => setItemsPerPage(10)}>10</MenuItem>
                            <MenuItem onClick={() => setItemsPerPage(20)}>20</MenuItem>
                            <MenuItem onClick={() => setItemsPerPage(50)}>50</MenuItem>
                            <MenuItem onClick={() => setItemsPerPage(100)}>100</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </div>
    );
}