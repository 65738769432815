//import navItems from "lib/nav-items";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
//import CommandBar from "@components/command-bar/command-bar";
import CommandBar from "../../../command-bar/command-bar";
import ReportResultsGrid from "../../../reports-common/report-results-grid/report-results-grid";
import ReportInput from "../../../reports-common/report-input/report-input";
import { AxiosError } from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { Button, Input, InputProps, Label, TableColumnDefinition, createTableColumn, useId } from "@fluentui/react-components";
import { TeamsFxContext } from "context/teams-context";
import { IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import {
  InfoRegular,
  DocumentRegular
} from "@fluentui/react-icons";
import { ICommandBarItem, ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import WorkspacePicker from "../../../controls/workspace-picker/workspace-picker";
import { IWorkspacePickerProps } from "../../../controls/workspace-picker/workspace-picker.props";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { PPAContext } from "context/ppa-context";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { IStorageInTenantReportDetails, IStorageInTenantReportItem, IStorageInWorkspaceReportItem } from "@interfaces/reports/storage-reports.interfaces";
import SizeCell from "components/reports-common/report-results-grid-cells/size-cell/size-cell";
import StoragePercentageCell from "components/reports-common/report-results-grid-cells/storage-percentage-cell/storage-percentage-cell";
import StorageInTenantReportSummary from "components/reports/storage/storage-in-tenant-report/storage-in-tenant-report-summary";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import WorkspaceLinkCell from "components/reports-common/report-results-grid-cells/workspace-link-cell/workspace-link-cell";
import { getSharePointTemplateKey } from "lib/workspaces";

//import StorageInTenantReportDetails from "components/reports/report-details/storage/storage-in-tenant-report-details";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/storage-in-tenant";


export default function StorageInTenantReport() {

  const { t } = useL10n<ILocaleStrings>();
  const [model, setModel] = useState<IRequestModel>({});

  // TODO: Each column defined somewhere central and put together here.
  const cols: Array<ITableColumn<IStorageInTenantReportItem>> = [
    {
      key: "workspaceName", label: t("ppaWorkspace"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: IStorageInTenantReportItem) => (<WorkspaceLinkCell name={item.workspaceName} url={item.workspaceUrl} template={item.workspaceTemplate} type={item.workspaceType} />),
      getExportValue: (key: string, item: IStorageInTenantReportItem) => (item.workspaceName ? item.workspaceName : t(getSharePointTemplateKey(item.workspaceTemplate)))
    },
    { key: "workspaceUrl", label: t("ppaWorkspaceUrl"), showInGrid: false, showInExport: true },
    {
      key: "totalSize", label: t("ppaTotalSize"), showInGrid: true, showInExport: false, defaultWidth: 200,
      renderCell: (key: string, item: IStorageInTenantReportItem) => (<SizeCell value={item.totalSize} valueUnits="B" />)
    },
    { key: "totalSize", label: t("ppaTotalSizeBytes"), showInGrid: false, showInExport: true },
    {
      key: "percentageOfQuota", label: t("ppaPercentageOfQuota"), showInGrid: true, showInExport: true, defaultWidth: 200,
      renderCell: (key: string, item: IStorageInTenantReportItem, details: IStorageInTenantReportDetails) => (
        <StoragePercentageCell
          percentageUsed={item.percentageOfQuota}
          usedValue={item.totalSize}
          usedValueUnits="B"
          maxValue={details.storageQuotaMB}
          maxValueUnits="MB" />)
    },
  ];

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<IStorageInTenantReportItem, IStorageInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <StorageInTenantReportSummary temp="the temp prop" />,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: t("ppaStorageInTenantReportNoItemsMessage"),
    defaultExportFilenamePrefix: "ppaStorageInTenantReportDefaultExportFilenamePrefix"
  }

  return (
    <ReportSection {...reportSectionProps}>
    </ReportSection>
  );
}
