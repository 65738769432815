import {
    Body1Stronger,
    Button,
    Caption1,
    Card,
    CardHeader,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    makeStyles,
    shorthands,
    tokens
} from "@fluentui/react-components";
import {
    OpenRegular,
    InfoRegular,
    ChevronRightRegular,
    BriefcasePerson24Regular,
    BoxMultipleArrowRight20Regular,
    bundleIcon,
    iconRegularClassName,
    FluentIcon
} from "@fluentui/react-icons";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { INavigationCardProps } from "./navigation-card.props";
import { INavigationCardChildItemProps } from "./navigation-card-child-item.props";
import { createElement } from "react";
import { Components } from "@mui/material";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        //...shorthands.padding('0', '2em'),
        ...shorthands.margin('0', '0', '1em', 0),
        display: 'flex'
    },
    navButtonWrapper: {
        flexGrow: 1
    },
    navButton: {
        width: '100%',
        justifyContent: 'flex-start'
    },
    infoButton: {

    }
});



export default function NavigationCardChildItem(props: INavigationCardChildItemProps) {
    const styles = layoutStyles();
    const navigate = useNavigate();
    const { t } = useL10n<ILocaleStrings>();

    // https://react.fluentui.dev/?path=/docs/concepts-developer-icons-icons--page
    //const ReportIcon = bundleIcon(props.item.icon, props.item.icon);

    const onSelect = () => {
        console.log(`onSelect -> `);
        navigate(props.item.route);
    }

    const icon = props.item.icon ? createElement(props.item.icon) : null;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.navButtonWrapper}>
                <Button
                    className={styles.navButton}
                    onClick={onSelect}
                    appearance="subtle"
                    icon={icon}
                    iconPosition="before">
                    {t(props.item.title)}
                </Button>
            </div>
            {props.item.description &&
                <Popover withArrow>
                    <PopoverTrigger disableButtonEnhancement>
                        <Button className={styles.infoButton} appearance="subtle" icon={<InfoRegular />}></Button>
                    </PopoverTrigger>
                    <PopoverSurface tabIndex={-1}>
                        <div>{t(props.item.description)}</div>
                    </PopoverSurface>
                </Popover>}
        </div>
    );
}