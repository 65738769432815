import { ChangeEvent, ChangeEventHandler, cloneElement, FocusEventHandler, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PPAContext } from "context/ppa-context";
import { TeamsFxContext } from "context/teams-context";
import { Field, Input, InputProps, Popover, PopoverProps, PopoverSurface, PopoverTrigger, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
//import { IWorkspacePickerInputProps } from "./workspace-picker-input.props";
import { IApiError, IWorkspace } from "@interfaces/common.interfaces";
//import WorkspacePickerItem from "./workspace-picker-item";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
//import WorkspacePickerResultsHeader from "./workspace-picker-results-header";
import { getError } from "lib/utils";
import WorkspaceCard from "components/common/workspace-card/workspace-card";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IObjectPickerInputProps } from "./object-picker-input.props";
import ObjectPickerResultsHeader from "./object-picker-results-header";

const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'relative'
    },
    inputWrapper: {
        //backgroundColor: 'red',
        ...shorthands.padding('0.5em'),
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('none', 'none', 'solid', 'none'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // TODO: Transition bottom border like the Input component.
    },
    input: {
        ...shorthands.borderStyle('none'),
        backgroundColor: 'transparent',
        ...shorthands.outline(0),
        width: '100%',
        height: '2em',
        fontFamily: 'var(--fontFamilyBase)',
        fontSize: 'var(--fontSizeBase300)'
    }
});


export default function ObjectPickerInput<T>(props: IObjectPickerInputProps<T>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const location = useLocation();
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const ppa = useContext(PPAContext);

    const [open, setOpen] = useState(false);
    const [results, setResults] = useState<Array<T> | null>(null);

    const [searchString, setSearchString] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [sentSearchString, setSentSearchString] = useState<string>();
    const [apiError, setApiError] = useState<IApiError | null>(null);

    // Create the api client for each of the api calls.
    const apiClient = useMemo(() => {
        console.log(`ObjectPickerInput -> useMemo -> createApiClient -> `);
        return createApiClient(
            ppa.appInstanceApiUrl || "",
            new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
        );
    }, [ppa.appInstanceApiUrl]);

    useEffect(() => {
        props.onResultsChange(results || []);
    }, [results]);

    // const hasResults = (): boolean => {
    //     return results?.length > 0 == true;
    // }

    const onSelect = (selection: T) => {
        console.log(`ObjectPickerInput -> onSelect -> `, selection);
        props.onSelect(selection);
        setOpen(false);
    }



    const onChange: ChangeEventHandler<HTMLInputElement> = (ev: ChangeEvent<HTMLInputElement>) => {
        console.log(`ObjectPickerInput -> onChange -> `, ev.target.value);

        //
        props.onChange(ev.target.value);

        //
        setSearchString(ev.target.value);
    }

    useEffect(() => {
        //
        const searchObjects = async (search: string) => {
            try {
                setLoading(true);
                setOpen(true);
                setSentSearchString(search);
                setApiError(null);
                const response = await apiClient.get<Array<T>>(props.getSearchUrl(search));
                setLoading(false);
                setResults(response.data ? response.data : null);
                setOpen(true);
            }
            catch (ex: unknown) {
                const error = getError(ex);
                console.log(`ObjectPickerInput -> searchObjects -> Error ->`, error);
                setApiError(error);
                setLoading(false);
                setOpen(false);
            }
        }
        if (loading) {
            return;
        }

        if (searchString.length > 0) {
            if (searchString !== sentSearchString) {
                searchObjects(searchString);
            }
        }
        else {
            setResults([]);
            setOpen(false);
            setSentSearchString("");
            setApiError(null);
        }
    }, [searchString, loading]);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) => {
        console.log(`ObjectPickerInput -> handleOpenChange -> `, e, data);
        //setOpen(data.open || false); // TODO: What is needed here??
        if (open && !data.open && !hasFocus) {
            setOpen(false);
        }
    }

    // FocusEvent<HTMLInputElement>
    const onFocus: FocusEventHandler<HTMLInputElement> = (ev: any) => {
        console.log(`ObjectPickerInput -> onFocus -> `, ev);
        // if (results?.length > 0) {
        //     setOpen(true);
        //     // TODO: This conflicts with handleOpenChange
        // }
        setHasFocus(true);

        // TODO: get the search string from the state??
        if (ev?.target?.value) {
            setOpen(true);
        }
    }
    const onBlur: FocusEventHandler<HTMLInputElement> = (ev: any) => {
        console.log(`ObjectPickerInput -> onBlur -> `, ev);
        setHasFocus(false);
    }

    return (
        <>
            {props.visible && <div className={styles.mainWrapper}>
                <Field validationMessage={apiError?.message}>
                    <Input
                        id={props.inputId}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoComplete="off"
                        placeholder={t("pickerFilterPlaceholder")}
                        appearance="underline" />
                </Field>
                <Popover inline trapFocus open={open} onOpenChange={handleOpenChange} size="large" positioning={"below-start"} >
                    <PopoverTrigger disableButtonEnhancement>
                        <span></span>
                    </PopoverTrigger>
                    <PopoverSurface>
                        <ObjectPickerResultsHeader
                            loading={loading}
                            searchString={searchString}
                            results={results} />
                        <div style={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }}>
                            {results?.map((object: T) => (
                                cloneElement(props.objectCard, { item: object, onSelect: onSelect, style: "subtle" })
                                // <WorkspaceCard
                                //     key={object.siteUrl}
                                //     item={object}
                                //     onSelect={onSelect}
                                //     style="subtle" />
                            ))}
                        </div>
                    </PopoverSurface>
                </Popover>
            </div>
            }
        </>
    );
}