import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, InlineDrawer, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    Dismiss24Regular,
    DismissRegular
} from "@fluentui/react-icons";
import { IReportActionInputProps } from "./report-action-input.props";
import Action from "components/actions-common/action/action";
import { PPAContext } from "context/ppa-context";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { getActionTypeKey } from "lib/actions";


const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        //backgroundColor: tokens.colorNeutralBackground2
    },
    background: {
        backgroundColor: tokens.colorNeutralBackground2
    },
    contentWrapper: {
        ...shorthands.padding('1em', 0)
    },
    errorWrapper: {

    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            ...shorthands.margin(0, 0, 0, '1em')
        }
    }
});

export default function ReportActionInput<T>(props: PropsWithChildren<IReportActionInputProps<T>>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    //const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    //const [error, setError] = useState<string>("");

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    // const onSubmitHandler = () => {
    //     console.log(`onSubmitHandler -> `);
    //     // setError("");
    //     // setIsSubmitting(true);
    //     // props.onSubmit().then(
    //     //     (data: any) => {
    //     //         console.log(`ReportActionInput -> onSubmitHandler -> data -> `, data);
    //     //         props.onClose();
    //     //         setIsSubmitting(false);
    //     //     },
    //     //     (error: any) => {
    //     //         console.log(`ReportActionInput -> onSubmitHandler -> error -> `, error);
    //     //         // TODO: Show the error in an error panel...
    //     //         // TODO: Nicer. Like a helper for getting the message from an error. Use an error interface that has code, error message, validation errors, etc...
    //     //         if (error.message) {
    //     //             setError(error.message);
    //     //         }
    //     //         setIsSubmitting(false);
    //     //     });
    // }

    return (
        <>
            {props.actionDefinition &&
                <InlineDrawer
                    className={styles.mainWrapper}
                    open={isOpen}
                    position="end"
                    size="medium"
                    separator>
                    <DrawerHeader className={styles.background}>
                        <DrawerHeaderTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={props.onClose}
                                />
                            }>
                            {t(getActionTypeKey(props.actionDefinition.actionType))}
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody className={styles.background}>
                        <Action
                            actionDefinition={props.actionDefinition}
                            reportRequestId={props.reportRequestId}
                            onCancel={props.onClose}
                            onRequestCallback={props.onRequestCallback} />
                    </DrawerBody>
                </InlineDrawer>}
        </>
    );
}